.request-event-form-requests-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr) 40px;
  margin-bottom: 50px;
  width: 90%;

  p {
    padding: 8px;
  }

  a {
    padding: 8px;
  }

  .border-bottom-gray {
    display: flex;
    align-items: flex-start;
  }

  .flex-center {
    align-items: center;
  }
}
