#practical-templates-list-wrapper {
  .ui.secondary.stackable.right.floated.custom-table-main-actions.menu {
    display: flex;
    height: 38px;
    margin-top: -38px !important;

    div.fitted.item {
      height: 37px !important;
    }

    .button.table__custom-white-outline-button {
      min-width: 155px;
      min-height: 37px;
      border-radius: 4px;
      background-color: #474b4f !important;
      color: white !important;
      justify-self: flex-end !important;
      border: 1px solid #474b4f;
      opacity: 1;
      padding: 0px 2px;
      font-size: 12px;
      letter-spacing: 0px;
      font-weight: 500;
      margin-right: 5px;
    }
  }

  .tna__inputs-wrapper {
    width: 74% !important;

    .adjust-filter-height {
      min-height: 38px !important;
      height: -webkit-fit-content !important;
      height: -moz-fit-content !important;
      height: fit-content !important;
    }

    .practical-boolean-editor {
      margin-right: 12px;
      margin-top: 10px;
      height: -webkit-fit-content !important;
      height: -moz-fit-content !important;
      height: fit-content !important;
      min-height: 38px;
    }

    .question-bank__multiple-dropdown.question-bank__multiple-dropdown.question-bank__multiple-dropdown {
      min-height: -webkit-fit-content !important;
      min-height: -moz-fit-content !important;
      min-height: fit-content !important;
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
    }

    #practical-template-list-event-types-filter {
      max-width: 460px !important;
    }

    .ensure-200px-width {
      min-width: 200px !important;
      width: 200px !important;
    }

    .ensure-175px-width {
      min-width: 175px !important;
      width: 175px !important;
    }

    .ensure-200px-min-width {
      min-width: 200px !important;
    }
  }
}
