.theoretical-templates__wrapper .theoretical-templates__filters {
  display: flex;
  flex-wrap: wrap;
  width: 70%;
  margin-top: -8px;
  z-index: 10;
}
.theoretical-templates__wrapper .theoretical-templates__filters .custom-editor {
  margin-top: 8px;
  margin-right: 16px;
  min-width: 150px !important;
  max-width: 150px !important;
  min-height: 38px !important;
  height: 38px !important;
}
.theoretical-templates__wrapper .theoretical-templates__filters .custom-editor-search {
  margin-top: 8px;
  margin-right: 16px;
}
.theoretical-templates__wrapper .ui.table.custom-table thead tr:first-child > th:first-child {
  padding-left: 15px !important;
}
.theoretical-templates__wrapper .ui.table.custom-table tbody tr > td:first-child {
  padding-left: 15px !important;
}
.theoretical-templates__wrapper .button.table__custom-white-outline-button {
  min-width: 155px;
  min-height: 37px;
  border-radius: 4px;
  background-color: #474b4f !important;
  color: white !important;
  justify-self: flex-end !important;
  border: 1px solid #474b4f;
  opacity: 1;
  padding: 0px 2px;
  font-size: 12px;
  letter-spacing: 0px;
  font-weight: 500;
  margin-right: 5px;
}
