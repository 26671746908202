@import '../../../../assets/less/variables.less';
@import '../shared-scheduler-components/request-drag.style.less';

@media screen and (max-width: 1560px) {
  .sch-locations__header {
    .sch-locations__header-below {
      flex-wrap: nowrap !important;

      .sch-locations__header-left {
        flex-wrap: wrap;

        b {
          margin-left: 0 !important;
        }

        div.viewpreset {
          margin-right: 8px;
        }
      }

      .sch-locations__header-right {
        margin-left: 16px;
        margin-bottom: 16px;

        .sch-locations__header-right-filters {
          flex-wrap: wrap !important;
          align-self: center !important;
          margin-left: 0 4px;

          & > div {
            margin: 4px;
          }
        }
      }
    }
  }
}

.b-mask {
  z-index: 4;
}

.sch-locations__inputs-hidden-wrapper {
  margin: 0px;
  padding: 0px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
}

.sch-locations__header-right-filters {
  margin: 0px;
  padding: 0px;
  display: flex;
  justify-content: flex-end;
}

.sch-locations__container {
  display: flex;
  height: 100%;
  flex-direction: column;

  .sch-locations__scheduler-component {
    display: flex;
    height: 100%;
    overflow: hidden;

    &.grow {
      height: 90%;
    }

    .b-react-scheduler-container {
      width: calc(80% - 24px);
      max-height: 100%;
      .b-grid-header-container {
        height: 64px;
        background-color: #f3f4f5 !important;
        margin: 0 !important;
      }

      .b-grid-header {
        padding: 0;
      }

      .b-grid-headers-locked .b-grid-header-text {
        text-transform: capitalize;
        text-align: center;
        font-size: 14px;
        padding: 0.5em 0;
        color: #222629 !important;
        font-weight: bold;
      }

      .b-filter-bar-field {
        margin: 0;

        .b-field-inner {
          border: 1px solid @inverted-color;
          border-radius: 4px;

          .b-icon-remove {
            padding-right: 0.8em;
          }
        }

        .b-filter-bar-field-input {
          padding: 0.2em 0.1em 0.2em 1.5em;
          height: 32px;
        }
      }

      .b-grid-headers-normal .b-sch-header-row {
        text-transform: capitalize;
        text-align: center;
        font-size: 14px;

        .b-sch-header-timeaxis-cell {
          font-weight: bold !important;
          // padding: 0.2em 0;
          color: #222629 !important;
        }
      }

      .b-container {
        border-radius: 8px 0 0 8px;
        background: #f2f3f3;
      }

      &.collapse {
        width: calc(100% - 24px);
      }
    }

    .request-table-container {
      display: flex;
      max-height: 100%;
      width: 20%;

      &.collapse {
        width: 0;
      }
    }
  }
}

div.sch-locations__inputs-hidden-wrapper > div {
  min-width: 140px !important;
  height: 38px;
  border: 1px solid @inverted-color !important;
  border-radius: 4px;
  margin-right: 8px;
}

.sch-locations__header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .sch-locations__header-above {
    display: flex;
  }

  .sch-locations__header-below {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .sch-locations__header-left {
    // padding: 16px;
    display: flex;
    align-items: center;

    b {
      margin-left: 8px;
      align-self: flex-start;
    }
  }

  .sch-locations__header-right {
    // padding-bottom: 16px;
    display: flex;
    align-items: center;

    .sch-locations__header-right-buttons {
      align-content: center;
      // flex-wrap: wrap;
      display: flex;

      i {
        font-size: 16px;
      }

      button {
        margin-left: 8px;
      }

      .event-type-filter-icon-btn {
        width: 38px;
        height: 38px;
        background: @inverted-color;
        cursor: pointer;
        border-radius: 4px;
        text-align: center;
        border: none;
        outline: none;

        &.active {
          background: #00b2a9 !important;
        }

        .hide-filter {
          text-shadow: unset !important;
          color: white !important;
          opacity: 1;
          background-color: red;
          border-radius: 1em;
          font-size: 0.7rem;
          height: 12px;
          width: 12px;
          line-height: 11px;
          left: 44%;
          bottom: 10%;
        }

        i.calendar-gears {
          margin: 0;
        }
      }
    }
  }

  .planit-multi-dropdown {
    min-width: 140px !important;
    height: 38px;
    border-radius: 4px;
    margin-right: 8px;
    border: 1px solid @inverted-color !important;
  }
}

.inputs-visible-wrapper {
  margin: 0px;
  padding: 0px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 16px;
}

.b-grid-subgrid-normal {
  background: white;
}

.locations-component__wrapper {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  // align-content: center;
}

.b-sch-event {
  padding: 0;
  background-color: white !important;
  color: black;
  border-radius: 4px;
  border-width: 2px 2px 2px 10px;
  border-style: solid;
  border-image: initial;

  &.draft {
    opacity: 0.6;
  }

  &.mini-event {
    min-width: 8px !important;

    .event-template__state {
      color: white !important;

      svg {
        display: inline;
        color: white !important;
      }
    }
  }

  &.requested-location {
    border-style: dashed dashed dashed solid;
    border-right-color: #dedfdf !important;
    border-top-color: #dedfdf !important;
    border-bottom-color: #dedfdf !important;
    .event-template__wrapper {
      background-image: repeating-linear-gradient(45deg, #cccccc60, #cccccc6e 2px, #ffffff3b 2px, #ffffff2d 10px);
      .event-template__state {
        color: @inverted-color!important;
      }
    }
  }

  .nested-pause-period {
    z-index: 0;
    position: absolute;
    border: none;
    background-image: repeating-linear-gradient(45deg, #ddd, #ddd 10px, #eee 10px, #eee 20px);
  }

  &:hover {
    background-color: #f7f7f7 !important;

    .nested-pause-period {
      background-image: repeating-linear-gradient(45deg, #ccc, #ccc 10px, #ddd 10px, #ddd 20px) !important;
    }
  }
}

.b-sch-event:not(.b-milestone).b-sch-event-selected,
.b-sch-event:not(.b-milestone).b-sch-event-selected * {
  font-weight: bold !important;
  color: #222629 !important;
}

.event-template__wrapper {
  overflow: hidden;
  padding-left: 0.75em;
  width: 100%;
  height: 100%;
  color: @inverted-color;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 1;

  i.b-fa {
    font-size: 16px;
  }

  .event-template__state {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;

    .event-template__state-section {
      align-self: center;
      padding-right: 16px;
    }

    .event-template__info {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .event-template__info-title {
        margin-bottom: 8px;
      }

      .event-template__info-items {
        display: flex;
        justify-content: flex-start;

        div {
          margin-right: 8px;
        }
      }
    }
  }

  .event-template__conditionals {
    justify-self: self-end;
    color: #222629;

    i.b-fa-circle {
      color: #e03c31 !important;
    }
  }
}

.grid-splitter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0 8px 8px 0;
  background: @default-grey;
  min-width: 24px;

  &:hover {
    background: #d3d3d3;
  }

  cursor: pointer;

  &::before {
    font-size: 16px;
  }
}

.event-vacation-template__wrapper {
  padding-left: 0.75em;
  width: 100%;
  height: 100%;
  color: #474b4f;
  background-color: #bdc4cb;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  z-index: 1;

  .event-vacation-template__info-title {
    font-size: 1.5rem;
  }

  svg {
    margin-left: 2px;
    margin-right: 8px;
  }
}
